import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Node } from '../shared/models/node.model';
import { NodeSelectService } from '../shared/services/node-select.service';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'orgchart-node',
  templateUrl: './chart-node.component.html',
  styleUrls: ['./chart-node.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      state(
        'collapsed',
        style({
          transform: 'translateY(-50px)',
          opacity: 0
        })
      ),
      transition('expanded => collapsed', [animate('0.2s')]),
      transition('collapsed => expanded', [animate('0.2s')])
    ])
  ]
})
export class ChartNodeComponent implements OnInit {

  @Input() datasource: Node;
  @Input() nodeHeading;
  @Input() nodeContent;
  @Input() nodeTemplate: TemplateRef<any>;
  @Input() groupScale: number;
  @Input() select: string;

  @Output() nodeClick = new EventEmitter<any>();

  Arr = Array; // Array type captured in a variable
  isCollapsed = false;
  ecStyles: object;
  isSelected: boolean;
  subscription: Subscription;

  constructor(private nodeSelectService: NodeSelectService) {
    // subscribe to node selection status
    this.subscription = this.nodeSelectService.getSelect().subscribe(selection => {
      if (selection && selection.id) {
        this.isSelected = this.datasource.id === selection.id;
      } else { // clear selection when empty selection received
        this.isSelected = false;
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }

  toggleChildren() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleAnimStart(event) {
    if (this.isCollapsed) {
      // å¦ææå çæ¯æ ¹ç»ç¹çå­èç¹ï¼é£ä¹æ ¹ç»ç¹åä¸çè¿æ¥çº¿éè¦éè
      if (
        event.element.parentElement &&
        event.element.parentElement.parentElement &&
        event.element.parentElement.parentElement.classList.contains('orgchart')
      ) {
        event.element.previousElementSibling.classList.add('oc-is-collapsed');
      }
    } else { // åä¸å±å¼çæ¶åï¼éè¦é©¬ä¸æå­èç¹ä»¬æ¾ç¤ºåºæ¥ï¼ä»¥ä¾¿çå°âä¸æ»âææ
      this.ecStyles = {
        display: 'flex'
      };
    }
  }

  toggleAnimEnd(event) {
    if (this.isCollapsed) { // æå æ¶ï¼âä¸æ»âå¨ç»ç»æåï¼éè¦å°å­èç¹ä»¬éèï¼ä»¥ä¾¿å¶ä¸å ç¨ç©ºé´
      this.ecStyles = {
        display: 'none'
      };
    } else {
      // å¦æå±å¼çæ¯æ ¹ç»ç¹çå­èç¹ï¼é£ä¹æ ¹ç»ç¹åä¸çè¿æ¥çº¿éè¦æ¾ç¤º
      if (
        event.element.parentElement &&
        event.element.parentElement.parentElement &&
        event.element.parentElement.parentElement.classList.contains('orgchart')
      ) {
        event.element.previousElementSibling.classList.remove('oc-is-collapsed');
      }
    }
  }

  onClickNode(e) {
    this.nodeClick.emit(this.datasource);
    if (this.select === 'single') {
      this.nodeSelectService.sendSelect(this.datasource.id);
    } else if (this.select === 'multiple') {
      this.isSelected = !this.isSelected;
    }
  }

  onNodeClick(e) {
    this.nodeClick.emit(e);
  }

}
